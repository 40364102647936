import { useState, useEffect, useContext, useMemo } from 'react';
import { ToastContent, ToastOptions, toast } from 'react-toastify';
import { IAgoraRTCClient } from 'agora-rtc-sdk-ng';
// @mui
import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  Typography,
  styled,
} from '@mui/material';
// api
import { unfollowArtist } from '~api/fan';
// components
import { HighlightText } from '~components/molecules';
import useFollowing from '~components/custom-hook/useFollowing';
import useDispatchPopup from '~components/custom-hook/useDispatchPopup';
import ShortcutIcon from '@mui/icons-material/Shortcut';
// utils
import {
  AgoraVideoContext,
  useGlobalDispatch,
  useGlobalState,
} from '~utils/container';
import {
  FEATURE_FLAGS_TYPE,
  POPUP_TYPE,
  PopupTypeValues,
  USER_TYPE,
  toastConfig,
} from '~utils/constants';
//
import ArtistSpotlightMessage from './artist-spotlight-message';
import ShareProfileModal from '~components/organisms/popup/share-profile-modal';
import { useBoolean } from 'react-use';
import { IOSSwitch } from '../popup/setting-dashboard-modal/artist-setting-dashboard-modal';
import { getAttendeesStats } from '~components/index';
import { useFeatureFlag } from '~hooks/useFeatureFlag';
import Popover from '@mui/material/Popover';
import { updateLayoutCloudRecording } from '~api/event';

const StyledDivider = styled(Box)(({ theme }) => ({
  position: 'absolute',
  left: 0,
  bottom: -4,
  width: 92,
  height: 4,
  backgroundColor: theme.palette.warning.main,
  [theme.breakpoints.up('md')]: {
    height: 8,
    bottom: -16,
  },
}));

const StyledLabelSwitch = styled(IOSSwitch)(() => ({
  width: 50,
  '& .MuiSwitch-switchBase': {
    '&.Mui-checked': {
      transform: 'translateX(24px)',
    },
  },
  '&:after, &:before': {
    color: 'white',
    fontSize: '8px',
    position: 'absolute',
    top: '6px',
  },
  '&:after': {
    content: "'ON'",
    left: '8px',
  },
  '&:before': {
    content: "'OFF'",
    right: '7px',
  },
}));

const Title = () => (
  <Box position={'relative'} mb={3}>
    <Typography
      variant="subtitle1"
      fontSize={{
        xs: 20,
        md: 30,
      }}
      textTransform={{
        xs: 'capitalize',
        md: 'uppercase',
      }}
      lineHeight={'39px'}
      fontWeight={'400'}
      noWrap
    >
      Fan Spotlight
    </Typography>
    <StyledDivider />
  </Box>
);

type Props = {
  title: string;
  text: string;
};

const AttendeeDropdownItem = ({ title, text }: Props) => {
  return (
    <Stack direction="row" width="100%">
      <Typography
        variant="body2"
        fontSize={{ lg: 14 }}
        fontWeight={300}
        lineHeight={'24px'}
      >
        {title}:
      </Typography>
      <Typography
        component={'span'}
        variant="h4"
        fontWeight={400}
        lineHeight={'24px'}
        marginLeft={'auto'}
      >
        {text}
      </Typography>
    </Stack>
  );
};

const ArtistSpotlight = () => {
  const { isEnable } = useFeatureFlag();
  const isEnableShowingAttendeeStats = isEnable(
    FEATURE_FLAGS_TYPE.SHOW_ATTENDEES_STATS,
  );
  const isEnableArchivedVideos = isEnable(
    FEATURE_FLAGS_TYPE.ARCHIVED_VIDEO_FLOW,
  );
  const globalState = useGlobalState();
  const {
    config: {
      remoteUsers,
      isFanLive,
      isArtistLive,
      localVideoTrack,
      localAudioTrack,
      isFanspotLightOpen = true,
      showShuffleButton = false,
      current_attendies: currentAttendees = 0,
      uniqueAttendees = 0,
      isShowed: isAttendeeStatShowed = true,
    },
    user: { id: userId, type, is_following, username, walletBalanceFan },
    artist: {
      currentEvent,
      id: artistId,
      // displayUsername,
      username: artistUsername,
      name,
    },
    app: { shuffle, stageFanUsername },
  }: Record<string, any> = globalState;
  const isArtist = type === USER_TYPE.ARTIST;
  const dispatch = useGlobalDispatch();
  const { showBuyLiveCoinPopup } = useDispatchPopup();
  const [isConnecting, setIsConnecting] = useState(false);
  const [isAttendeeDropdownOpened, setIsAttendeeDropdownOpened] =
    useState(false);
  const [showShareProfileModal, setShowShareProfileModal] = useBoolean(false);
  const agoraVideo = useContext(AgoraVideoContext);
  // const socket = useContext(SocketContext);
  const { fetchFollowingStatus } = useFollowing();
  const { connectionState: agoraVideo_connectionState } = agoraVideo || {};
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [popoverOnTop, setPopoverOnTop] = useState<boolean>(false);

  const isDisplayCurrentAttendee = useMemo(() => {
    if (type === USER_TYPE.ARTIST) {
      return !!localVideoTrack;
    } else {
      const artistAudioVideo = remoteUsers.find(
        (user: { uid: string }) => user.uid === artistUsername,
      );
      return !!artistAudioVideo?.hasVideo;
    }
  }, [artistUsername, localVideoTrack, remoteUsers, type]);

  const handleTogglePopup = (popup: PopupTypeValues) => {
    dispatch({
      type: 'app',
      payload: { popup },
    });
  };

  const handleToggleAttendeeStatDisplayed = async () => {
    await getAttendeesStats(currentEvent, !isAttendeeStatShowed, dispatch);
  };

  const handleAnchorPosition = () => {
    const viewportHeight = window.innerHeight;
    const anchorElRect = anchorEl?.getBoundingClientRect();

    if (anchorElRect) {
      const anchorElBottomSpace = viewportHeight - anchorElRect.bottom;
      setPopoverOnTop(anchorElBottomSpace < 100);
    }
  };

  const leaveEvent = async () => {
    await (agoraVideo as IAgoraRTCClient).unpublish();
    await (agoraVideo as IAgoraRTCClient).setClientRole('audience');
    localAudioTrack && (await localAudioTrack.close());
    localVideoTrack && (await localVideoTrack.close());

    // Update recording layout to single
    // TODO: Refactor to handle leave event in 1 place
    if (stageFanUsername === username) {
      const agoraVideoAlias: any = agoraVideo;
      const audienceUid: number = agoraVideoAlias?._joinInfo.uid;
      await updateLayoutCloudRecording(currentEvent, audienceUid, false);
      dispatch({
        type: 'app',
        payload: {
          stageFanId: '',
          stageFanUsername: '',
        },
      });
    }

    const payload = {
      localAudioTrack: null,
      localVideoTrack: null,
      isFanLive: false,
    };
    dispatch({
      type: 'config',
      payload,
    });
  };

  const publishVideo = () => {
    handleTogglePopup(POPUP_TYPE.STAGE);
  };

  const handleReward = () => {
    handleTogglePopup(POPUP_TYPE.REWARDS);
  };

  const handleFollow = async () => {
    if (!userId) {
      handleTogglePopup(POPUP_TYPE.LOGIN);
      return;
    }
    setIsConnecting(true);
    if (is_following) {
      try {
        await unfollowArtist(artistId);
        dispatch({ type: 'user', payload: { is_following: !is_following } });
      } catch (err) {
        // TODO need to define data type
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        toast(err as ToastContent<unknown>, toastConfig as ToastOptions);
      }
    } else {
      handleTogglePopup(POPUP_TYPE.FOLLOW);
    }
    setIsConnecting(false);
  };

  const handleTip = async () => {
    if (!userId) {
      handleTogglePopup(POPUP_TYPE.LOGIN);
      return;
    }

    if (parseInt(walletBalanceFan, 10) > 0) {
      handleTogglePopup(POPUP_TYPE.TIP);
    } else {
      showBuyLiveCoinPopup();
    }
  };

  useEffect(() => {
    if (artistUsername && username && username !== artistUsername) {
      fetchFollowingStatus();
    }
  }, [artistUsername, fetchFollowingStatus, username]);

  const joinLeaveStream = () => {
    if (!userId) {
      handleTogglePopup(POPUP_TYPE.LOGIN);
      return;
    }
    isFanLive ? leaveEvent() : publishVideo();
  };

  return (
    <Grid
      container
      spacing={2}
      pb={3}
      direction={{
        xs: 'column-reverse',
        sm: 'column',
        md: 'row',
      }}
    >
      <Grid item xs={12} md={7} lg={7} xl={8}>
        {isArtistLive && (
          <>
            <Stack
              direction={{
                xs: 'column-reverse',
                sm: 'row',
              }}
            >
              <Title />
              {isFanspotLightOpen && (
                <Stack
                  pb={{
                    xs: 0,
                    sm: 2,
                  }}
                  width={{
                    xs: '100%',
                    sm: 'auto',
                  }}
                  alignItems={'center'}
                  direction={{
                    xs: 'column-reverse',
                    sm: 'row',
                  }}
                  justifyContent={{
                    xs: 'center',
                    md: 'flex-end',
                  }}
                  spacing={2}
                  ml={{
                    xs: 0,
                    sm: 2,
                  }}
                >
                  {type === USER_TYPE.FAN &&
                    isArtistLive &&
                    agoraVideo_connectionState === 'CONNECTED' && (
                      <Button
                        fullWidth
                        sx={{ minWidth: 120, whiteSpace: 'nowrap' }}
                        variant="outlined"
                        color="warning"
                        onClick={joinLeaveStream}
                      >
                        {isFanLive ? 'LEAVE' : 'JOIN SPOTLIGHT'}
                      </Button>
                    )}
                  {!type && !userId && (
                    <Button
                      fullWidth
                      sx={{ minWidth: 120, whiteSpace: 'nowrap' }}
                      variant="outlined"
                      color="warning"
                      disabled={!isArtistLive}
                      onClick={() => handleTogglePopup(POPUP_TYPE.LOGIN)}
                    >
                      JOIN SPOTLIGHT
                    </Button>
                  )}
                  {showShuffleButton && (
                    <Button
                      fullWidth
                      sx={{ minWidth: 120 }}
                      variant={shuffle ? 'contained' : 'outlined'}
                      color="warning"
                      onClick={() =>
                        dispatch({
                          type: 'app',
                          payload: { shuffle: !shuffle },
                        })
                      }
                    >
                      {shuffle ? 'SHUFFLING' : 'SHUFFLE'}
                    </Button>
                  )}
                  {!isEnableArchivedVideos && (
                    <Button
                      fullWidth
                      sx={{ minWidth: 120 }}
                      variant={shuffle ? 'contained' : 'outlined'}
                      color="warning"
                      onClick={() => setShowShareProfileModal(true)}
                      endIcon={<ShortcutIcon />}
                    >
                      SHARE
                    </Button>
                  )}
                </Stack>
              )}
              <Box
                pt={{
                  xs: 0,
                  md: 1,
                }}
                pl={{
                  xs: 0,
                  md: 2,
                }}
                hidden={!isArtistLive}
              >
                {isEnableShowingAttendeeStats ? (
                  <>
                    {' '}
                    {isArtist ? (
                      <HighlightText
                        title={
                          isAttendeeStatShowed
                            ? 'Current Attendees:'
                            : 'Attendees Stats'
                        }
                        display={{
                          xs: 'inline-flex',
                          sm: 'flex',
                        }}
                        text={
                          isAttendeeStatShowed
                            ? isArtistLive && isDisplayCurrentAttendee
                              ? currentAttendees
                              : 0
                            : ''
                        }
                        isDropdownOpened={isAttendeeDropdownOpened}
                        onDropdownClicked={(
                          event: React.MouseEvent<HTMLElement>,
                        ) => {
                          setIsAttendeeDropdownOpened(
                            !isAttendeeDropdownOpened,
                          );
                          setAnchorEl(event.currentTarget);
                          handleAnchorPosition();
                        }}
                      />
                    ) : (
                      <HighlightText
                        title={'Current Attendees:'}
                        display={{
                          xs: 'inline-flex',
                          sm: 'flex',
                        }}
                        text={currentAttendees}
                      />
                    )}
                    <Popover
                      open={isAttendeeDropdownOpened}
                      anchorEl={anchorEl}
                      onClose={() => setIsAttendeeDropdownOpened(false)}
                      transformOrigin={{
                        vertical: popoverOnTop ? 'bottom' : 'top',
                        horizontal: 'left',
                      }}
                      anchorOrigin={{
                        vertical: popoverOnTop ? 'top' : 'bottom',
                        horizontal: 'left',
                      }}
                      sx={popoverOnTop ? { marginBottom: 2 } : { marginTop: 2 }}
                    >
                      <Stack
                        maxWidth={210}
                        sx={{
                          borderRadius: '4px',
                          border: '1px solid  rgba(255, 255, 255, 0.1)',
                        }}
                        divider={<Divider orientation="horizontal" flexItem />}
                      >
                        <MenuItem value="option1">
                          <AttendeeDropdownItem
                            title="Current Attendees"
                            text={
                              isArtistLive && isDisplayCurrentAttendee
                                ? currentAttendees
                                : 0
                            }
                          />
                        </MenuItem>
                        <MenuItem value="option2">
                          <AttendeeDropdownItem
                            title="Total Attendees"
                            text={
                              isArtistLive && isDisplayCurrentAttendee
                                ? uniqueAttendees
                                : 0
                            }
                          />
                        </MenuItem>
                        <MenuItem>
                          <FormControlLabel
                            control={
                              <StyledLabelSwitch
                                checked={isAttendeeStatShowed}
                                onChange={handleToggleAttendeeStatDisplayed}
                                sx={{ m: 1 }}
                              />
                            }
                            label={
                              <Typography
                                variant="body2"
                                fontSize={{ lg: 14 }}
                                fontWeight={300}
                                lineHeight={'24px'}
                                sx={{ marginRight: 'auto' }}
                              >
                                Show current attendees stat while streaming
                              </Typography>
                            }
                            sx={{ whiteSpace: 'normal' }}
                          />
                        </MenuItem>
                      </Stack>
                    </Popover>
                  </>
                ) : (
                  <HighlightText
                    display={{
                      xs: 'inline-flex',
                      sm: 'flex',
                    }}
                    title="Current Attendees:"
                    text={
                      isArtistLive && isDisplayCurrentAttendee
                        ? currentAttendees
                        : 0
                    }
                  />
                )}
              </Box>
            </Stack>
            <ArtistSpotlightMessage />
            <ShareProfileModal
              open={showShareProfileModal}
              onClose={() => setShowShareProfileModal(false)}
              isArtist={type === USER_TYPE.ARTIST}
              artistName={name || ''}
            />
          </>
        )}
      </Grid>
      {(!userId || type === USER_TYPE.FAN) && (
        <Grid item xs={12} md={5} lg={5} xl={4}>
          <Stack
            direction={'row'}
            justifyContent={{
              xs: 'center',
              md: 'flex-end',
            }}
            spacing={{
              xs: 2.5,
              sm: 1,
            }}
          >
            <Button
              fullWidth
              variant="contained"
              color="warning"
              onClick={handleReward}
            >
              REWARDS
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="warning"
              onClick={handleTip}
            >
              TIP
            </Button>
            <Button
              fullWidth
              variant="contained"
              color="warning"
              disabled={isConnecting}
              onClick={handleFollow}
            >
              {is_following ? 'FOLLOWING' : 'FOLLOW'}
            </Button>
          </Stack>
        </Grid>
      )}
    </Grid>
  );
};

export default ArtistSpotlight;
